<template>
    <div class="flex justify-center items-start pb-10">
        
        <div v-if="!isFetching" class="mt-10 w-3/4">

            <div class=" flex justify-between items-center">
                <h2 class=" tracking-wide font-semibold text-2xl text-warm-gray-700 dark:text-warm-gray-50">Reward Claimants</h2>
                <div class=" flex justify-end items-center">

                    <div class=" rounded-md overflow-hidden border border-warm-gray-600 bg-warm-gray-700 mr-2">
                        <button @click="changeTab('pendings')" class="py-1 px-2 hover:bg-warm-gray-800" :class="currentTab == 'pendings' && 'bg-warm-gray-800'">Pending</button>
                        <button @click="changeTab('rejected')" class="py-1 px-2 hover:bg-warm-gray-800" :class="currentTab == 'rejected' && 'bg-warm-gray-800'">Rejected</button>
                        <button @click="changeTab('approved')" class="py-1 px-2 hover:bg-warm-gray-800" :class="currentTab == 'approved' && 'bg-warm-gray-800'">Approved</button>
                        <button @click="changeTab('shipped')" class="py-1 px-2 hover:bg-warm-gray-800" :class="currentTab == 'shipped' && 'bg-warm-gray-800'">Shipped</button>
                        <button @click="changeTab('received')" class="py-1 px-2 hover:bg-warm-gray-800" :class="currentTab == 'received' && 'bg-warm-gray-800'">Received</button>
                        <button @click="changeTab('forfeited')" class="py-1 px-2 hover:bg-warm-gray-800" :class="currentTab == 'forfeited' && 'bg-warm-gray-800'">Forfeited</button>
                        <button @click="changeTab()" class="py-1 px-2 hover:bg-warm-gray-800" :class="currentTab == null && 'bg-warm-gray-800'">All</button>
                    </div>
                    <button @click="getClaimants(currentTab)" class=" rounded-full bg-warm-gray-200 text-warm-gray-500 hover:bg-warm-gray-300 dark:bg-warm-gray-800 dark:text-warm-gray-600 dark:hover:bg-warm-gray-700 p-1.5">
                        <ArrowPathIcon class=" w-5 h-5" />
                    </button>

                </div>
            </div>

            <table class=" mt-5 w-full">
                <thead>
                    <tr class="bg-warm-gray-300 border-warm-gray-500 text-warm-gray-600 dark:text-warm-gray-300 dark:bg-warm-gray-700 dark:border-warm-gray-300 border-b-2 border-t-2 ">
                        <th class=" text-left py-2 pl-2">Email</th>
                        <th class=" text-center">Achievement Box</th>
                        <th class=" text-center">Post Exam Survey</th>
                        <th class=" text-center">FB Post Date</th>
                        <th class=" text-center">Updated On</th>
                        <th class=""></th>
                    </tr>
                </thead>
                <tbody >
                    <tr v-for="(claimant, i) in filteredClaimants" :key="i" class="border-b border-dashes border-warm-gray-600 hover:bg-warm-gray-100 dark:border-warm-gray-600 dark:hover:bg-warm-gray-800">
                        <td class="text-left pl-2 py-1.5">{{ claimant.data.email }}</td>
                        <td class="text-center">
                            <span v-if="claimant.data.achievementBoxInfoStatus == 'submitted'" class="rounded-full bg-warm-gray-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.achievementBoxInfoStatus }}
                            </span>
                            <span v-else-if="claimant.data.achievementBoxInfoStatus == 'rejected'" class="rounded-full bg-red-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.achievementBoxInfoStatus }}
                            </span>
                            <span v-else-if="claimant.data.achievementBoxInfoStatus == 'approved'" class="rounded-full bg-green-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.achievementBoxInfoStatus }}
                            </span>
                            <span v-else-if="claimant.data.achievementBoxInfoStatus == 'shipped'" class="rounded-full bg-indigo-500 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.achievementBoxInfoStatus }}
                            </span>
                            <span v-else class="rounded-full bg-yellow-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.achievementBoxInfoStatus }}
                            </span>
                        </td>
                        <td class="text-center">
                            <span v-if="claimant.data.postExamSurveyInfoStatus == 'submitted'" class="rounded-full bg-warm-gray-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.postExamSurveyInfoStatus }}
                            </span>
                            <span v-else-if="claimant.data.postExamSurveyInfoStatus == 'rejected'" class="rounded-full bg-red-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.postExamSurveyInfoStatus }}
                            </span>
                            <span v-else-if="claimant.data.postExamSurveyInfoStatus == 'approved'" class="rounded-full bg-green-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.postExamSurveyInfoStatus }}
                            </span>
                            <span v-else-if="claimant.data.postExamSurveyInfoStatus == 'shipped'" class="rounded-full bg-indigo-500 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.postExamSurveyInfoStatus }}
                            </span>
                            <span v-else class="rounded-full bg-yellow-700 text-white text-xs px-2 py-0.5">
                                {{ claimant.data.postExamSurveyInfoStatus }}
                            </span>
                        </td>
                        <td class="text-center">{{ claimant.data.facebookPostDate ? formatDate(claimant.data.facebookPostDate) : '--'  }}</td>
                        <td class="text-center">{{ formatDate(claimant.data.metadata.updatedOn)  }}</td>
                        <td>
                            <router-link :to="'reward/view/' + claimant.id" tag="button" class="border border-yellow-600 px-1 text-yellow-600 rounded">view</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script setup>

import {ref, onMounted} from 'vue'
import {fetchClaimants} from '../firebase/index'
import {ArrowPathIcon} from '@heroicons/vue/24/outline'

const isFetching = ref(false);
const claimants = ref([]);
const filteredClaimants = ref([]);
const currentTab = ref('pendings');

const getClaimants = async (curTab = null) => {
    isFetching.value = true;
    currentTab.value = curTab;
    claimants.value = [];
    claimants.value.push(...await fetchClaimants())
    changeTab(curTab);
    isFetching.value = false;
}

const changeTab = (curTab = null) => {

    filteredClaimants.value = [];
    currentTab.value = curTab;

    console.log(claimants.value);

    switch (curTab) {
        case 'pendings':
            filteredClaimants.value = claimants.value.filter((claimant) => !claimant.data.isForfeited && ((claimant.data.achievementBoxInfoStatus == 'submitted' || claimant.data.postExamSurveyInfoStatus == 'submitted') && (claimant.data.achievementBoxInfoStatus != 'rejected' && claimant.data.postExamSurveyInfoStatus != 'rejected')) )
            break;
        case 'rejected':
            filteredClaimants.value = claimants.value.filter((claimant) => !claimant.data.isForfeited && (claimant.data.achievementBoxInfoStatus == 'rejected' || claimant.data.postExamSurveyInfoStatus == 'rejected') )
            break;
        case 'approved':
            filteredClaimants.value = claimants.value.filter((claimant) => !claimant.data.isForfeited && (claimant.data.achievementBoxInfoStatus == 'approved' && claimant.data.postExamSurveyInfoStatus == 'approved'))
            break;
        case 'shipped':
            filteredClaimants.value = claimants.value.filter((claimant) => !claimant.data.isForfeited && claimant.data.achievementBoxInfoStatus == 'shipped')
            break;
        case 'received':
            filteredClaimants.value = claimants.value.filter((claimant) => !claimant.data.isForfeited && claimant.data.achievementBoxInfoStatus == 'received')
            break;
        case 'forfeited':
            filteredClaimants.value = claimants.value.filter((claimant) => claimant.data.isForfeited)
            break;
        default:
            filteredClaimants.value = [...claimants.value];
            break;
    } 

}

onMounted(async ()=>{
    await getClaimants(currentTab.value);
})


// ########### Helper functions #########//

const formatDate = (timestampInSeconds) => {

    // const timestampInMilliseconds = timestampInSeconds * 1000;
    const date = new Date( parseInt(timestampInSeconds));

    // Define an array to map month numbers to month names
    const months = [
    "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
    ];

    // Get the month, day, and year from the date object
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Construct the formatted date string
    return `${month} ${day}, ${year}`;

}


</script>